import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from './Header';
import Footer from './Footer';
import ScrollTop from './ScrollTop';
import GlobalStyles from './GlobalStyles';
import CookieControl from './CookieControl';
import logo from '../images/logo.svg';

/**
 * Rendert ein Standard-Layout
 * @param {Node} props.children Der Inhalt
 * @param {string} props.title Der Seitentitel
 * @param {string} props.description Die Meta-Description
 */
const Layout = ({ children, title, description }) => (
    <StaticQuery
        query={graphql`
            {
                allPagestructureJson {
                    edges {
                        node {
                            url
                            title
                            subpages {
                                title
                                anchor
                                url
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            const pageStructure = data.allPagestructureJson.edges.map(({ node }) => node);
            return (
                <>
                    <Helmet>
                        <title>{`Gottwald Elektrotechnik - ${title}`}</title>
                        <html lang="de" />
                        <meta name="description" content={description} />
                        {/* eslint-disable */}$
                        {process.env.NODE_ENV === 'production' && (
                            <script type="text/javascript">
                                {`
                                    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                                    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                                    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                                    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                                    ga('set', 'anonymizeIp', true);
                                    ga('create', 'UA-158908117-1', 'auto');
                                    ga('send', 'pageview');
                                `}
                            </script>
                        )}
                        {/* eslint-enable */}
                    </Helmet>
                    <GlobalStyles />
                    <Header
                        pageStructure={pageStructure}
                        logo={{
                            image: logo,
                            alt: 'Logo von Gottwald Elektrotechnik',
                            title: 'Gottwalkd Elektrotechnik',
                        }}
                    />
                    {children}
                    <Footer />
                    <ScrollTop />
                    <CookieControl />
                    {/* eslint-disable react/no-danger */}
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: `
                            {
                                "@context": "http://schema.org",
                                "@type": "Organization",
                                "name": "Gottwald Elektrotechnik GmbH",
                                "description": "Die Gottwald Elektrotechnik GmbH hat ihren Firmensitz in Durlangen und bietet moderne Elektrotechnik & Smart Home",
                                "logo": "https://www.gottwald-elektrotechnik.de/logo.png",
                                "url": "https://www.gottwald-elektrotechnik.de",
                                "telephone": "+0 71 76 / 23 98",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "Durlanger Str. 24",
                                    "addressLocality": "Durlangen",
                                    "postalCode": "73568",
                                    "addressCountry": "Germany"
                                }
                            }
                        `,
                        }}
                    />
                    {/* eslint-enable react/no-danger */}
                </>
            );
        }}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
};

Layout.defaultProps = {
    description:
        'Gottwald Elektrotechnik - Ihr kompetenter Partner in Sachen Photovoltaik, Elektrotechnik, Gebäude Automation (Smart Home).',
};

export default Layout;
