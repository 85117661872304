import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { CaretDown as FaCaretDown } from 'styled-icons/fa-solid/CaretDown';
import { CaretRight as FaCaretRight } from 'styled-icons/fa-solid/CaretRight';
import { CaretLeft as FaCaretLeft } from 'styled-icons/fa-solid/CaretLeft';
import { LongArrowAltUp as FaLongArrowUp } from 'styled-icons/fa-solid/LongArrowAltUp';
import { Envelope as FaMail } from 'styled-icons/fa-solid/Envelope';
import { Phone as FaPhone } from 'styled-icons/fa-solid/Phone';

import { ReactComponent as BarsIcon } from '../images/icons/bars.svg';
import { ReactComponent as CloseIcon } from '../images/icons/close.svg';

import { ReactComponent as arrowLeftIcon } from '../images/icons/arrow-left.svg';
import { ReactComponent as arrowRightIcon } from '../images/icons/arrow-right.svg';

import { colors } from '../helpers/variables';

/** Standard-Styles für die Icons */
const styledIcon = icon => styled(icon)`
    color: ${({ color }) => colors[color]};
    height: 1em;

    transform: rotate(${({ rotate }) => rotate || 0});
    transition: transform 150ms;
`;

/** Alle verfügbare Icons */
export const icons = {
    arrowLeft: styledIcon(arrowLeftIcon),
    arrowRight: styledIcon(arrowRightIcon),
    bars: styledIcon(BarsIcon),
    caretDown: styledIcon(FaCaretDown),
    caretRight: styledIcon(FaCaretRight),
    caretLeft: styledIcon(FaCaretLeft),
    close: styledIcon(CloseIcon),
    longArrowUp: styledIcon(FaLongArrowUp),
    mail: styledIcon(FaMail),
    phone: styledIcon(FaPhone),
};

/**
 * Icon Componente
 * @param {string} props.type Typ des Icons, aka der Name des gewünschten Icons
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {string} props.color Optional: Typ des Icons
 * @param {string} props.rotate Optional: Rotation des Icons
 */
const Icon = ({ type, className, color, rotate }) => {
    const IconElement = icons[type];
    return <IconElement className={className} color={color} rotate={rotate} />;
};

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    rotate: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(icons)).isRequired,
};

Icon.defaultProps = {
    className: null,
    color: 'currentColor',
    rotate: null,
};

export default Icon;
