import { rem, darken } from 'polished';

/**
 * Hover Transition Speed
 * @type {String}
 */
export const hoverSpeed = '0.25s';

/**
 * Schriftart
 * @type {Object}
 */
export const fontFamilies = {
    default: 'Ubuntu, Arial, sans-serif',
};

/**
 * Schriftgrößen
 * @type {Object}
 */
export const fontSizes = {
    inherit: 'inherit',
    base: `font-size: ${rem(16)}; font-size: var(--fs-base);`,
    h1: `font-size: ${rem(40)}; font-size: var(--fs-h1);`,
    h2: `font-size: ${rem(30)}; font-size: var(--fs-h2);`,
    h3: `font-size: ${rem(22)}; font-size: var(--fs-h3);`,
    h4: `font-size: ${rem(20)}; font-size: var(--fs-h4);`,
    h5: `font-size: ${rem(18)}; font-size: var(--fs-h5);`,
    h6: `font-size: ${rem(16)}; font-size: var(--fs-h6);`,
    xxxxl: `font-size: ${rem(50)}; font-size: var(--fs-xxxxl);`,
    xxxl: `font-size: ${rem(40)}; font-size: var(--fs-xxxl);`,
    xxl: `font-size: ${rem(32)}; font-size: var(--fs-xxl);`,
    xl: `font-size: ${rem(26)}; font-size: var(--fs-xl);`,
    l: `font-size: ${rem(20)}; font-size: var(--fs-l);`,
    m: `font-size: ${rem(18)}; font-size: var(--fs-m);`,
    s: `font-size: ${rem(16)}; font-size: var(--fs-s);`,
    xs: `font-size: ${rem(14)}; font-size: var(--fs-xs);`,
};

/**
 * Border-Radius
 */
export const borderRadiuses = {
    none: 0,
    s: rem(10),
    m: rem(20),
};

/**
 * Abstände
 * @type {Object}
 */
export const gaps = {
    none: 'margin-bottom: 0',
    s: `margin-bottom: ${rem(4)}; margin-bottom: var(--gap-s);`,
    m: `margin-bottom: ${rem(8)}; margin-bottom: var(--gap-m);`,
    l: `margin-bottom: ${rem(16)}; margin-bottom: var(--gap-l);`,
    xl: `margin-bottom: ${rem(24)}; margin-bottom: var(--gap-xl);`,
    xxl: `margin-bottom: ${rem(32)}; margin-bottom: var(--gap-xxl);`,
    xxxl: `margin-bottom: ${rem(48)}; margin-bottom: var(--gap-xxxl);`,
    xxxxl: `margin-bottom: ${rem(60)}; margin-bottom: var(--gap-xxxxl);`,
};

/**
 * Breiten der Container
 * @type {Object}
 */
export const containers = {
    auto: 'auto',
    fullWidth: '100%',
    s: rem(745),
    m: rem(970),
    l: rem(1230),
};

/**
 * Farben
 * @type {Object}
 */
export const colors = {
    transparent: 'transparent',
    currentColor: 'currentColor',
    black: '#000000',
    blue100: '#e6edf3',
    white: '#ffffff',
    gray100: '#eaeaea',
    gray300: '#dfdfdf',
    gray500: '#444444',
    gray700: '#1a1a1a',
    text: '#1a1a1a',
    primary: '#798b58',
    primaryDark: darken(0.2, '#798b58'),

    // state colors
    success: '#7aba4e',
    warning: '#ffae00',
    alert: '#cc4b37',
};

/**
 * Breakpoints
 * @type {Object}
 */
export const breakpoints = {
    xsmall: rem(0),
    small: rem(450),
    medium: rem(640),
    mediumDown: rem(639),
    large: rem(1024),
    largeDown: rem(1023),
    xlarge: rem(1200),
    xlargeDown: rem(1199),
    xxlarge: rem(1440),
    xxlargeDown: rem(1439),
    xxxlarge: rem(1680),
    xxxlargeDown: rem(1679),
};
