import React from 'react';
import { Link } from 'gatsby';
import { rem } from 'polished';
import styled from 'styled-components';

import { colors } from '../helpers/variables';
import { globalFramePadding, mq } from '../helpers/stylehelpers';

/** Wrapper der Komponente */
const Wrapper = styled.footer`
    ${globalFramePadding};
    /* Code oben (padding) setzt globles Padding für den Seitenrand */
    align-items: center;
    background-color: ${colors.primary};
    display: flex;
    flex-shrink: 0;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    justify-content: space-between;
    margin-top: auto;
    flex-direction: column;

    ${mq.mediumDown`
        font-size: ${rem(15)};
    `};

    ${mq.xlarge`
        flex-direction: row;
    `};
`;

/** Wrapper für den Copyright Hinweis */
const Copyright = styled.strong`
    text-align: center;
    color: ${colors.white};
    font-weight: 500;
    ${mq.largeDown`
        border-bottom: 1px solid ${colors.gray};
        margin: 0.8em 0;
        padding-bottom: 0.8em;
        width: 100%;
    `};
`;

/** Wrapper der die Links zum rechtlichen hält */
const LegalLinks = styled.div`
    font-weight: 500;
    color: ${colors.white};
`;

const StyledLink = styled(Link)`
    &,
    &:hover,
    &:focus {
        color: ${colors.white};
        text-decoration: none;
    }
    &:hover {
        text-decoration: underline;
    }

    &:first-child {
        margin-right: 0.5em;
    }
    &:last-child {
        margin-left: 0.5em;
    }
`;

/**
 * Footer-Komponente
 */
const Footer = () => (
    <Wrapper>
        <Copyright>
            &copy;
            {` ${new Date().getFullYear()} `}
            Gottwald Elektrotechnik
        </Copyright>
        <LegalLinks>
            <StyledLink to="/impressum">Impressum</StyledLink>
            <span>|</span>
            <StyledLink to="/datenschutz">Datenschutz</StyledLink>
        </LegalLinks>
    </Wrapper>
);

export default Footer;
