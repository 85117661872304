import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import { rem } from 'polished';

import { scrollTo } from '../helpers/utils';
import { colors } from '../helpers/variables';
import { mq } from '../helpers/stylehelpers';

import Toggle from './Toggle';

/**
 * Link
 * @type {StyledComponent}
 */
const Link = styled(Toggle)`
    background-color: ${colors.primaryColor};
    bottom: 15vh;

    position: fixed;
    right: ${rem(15)};
    transform: translateY(-50%);
    transition: opacity 0.25s, background-color 0.25s;
    z-index: 1001;

    > svg {
        color: ${colors.primary};
    }

    ${mq.large`
        right: ${rem(20)};
    `};

    ${mq.xxlarge`
        right: ${rem(45)};
    `};

    ${mq.xxxlarge`
        right: ${rem(60)};
    `};

    &:hover {
        > svg {
            color: ${colors.white};
        }
        background-color: ${colors.primary};
    }

    ${({ show }) =>
        show ? `opacity: 1; pointer-events:auto;` : `opacity: 0; pointer-events: none;`};
`;

const ScrollTop = () => {
    const [show, setShow] = useState(false);

    const threshold = 700;

    const handleScroll = () => {
        let isOverThreshold = false;
        if (window.scrollY > threshold) {
            isOverThreshold = true;
        }
        setShow(isOverThreshold);
    };

    const throttledScroll = throttle(handleScroll, 300);

    useEffect(() => {
        window.addEventListener('scroll', throttledScroll);
        return () => window.removeEventListener('scroll', throttledScroll);
    }, []);

    return (
        <Link
            href="#top"
            icon="longArrowUp"
            onClick={scrollTo}
            show={show}
            aria-label="nach oben"
        />
    );
};

export default ScrollTop;
